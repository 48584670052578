import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  doFacebookLogin(){
    
  }

  requestPin(phone: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
    };
    const url = `${environment.base_url}/login/telefono/${phone}`;
    return this.http.get<any>(url, httpOptions);
  }

  doPhoneNumber(data: any){
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
    };
    const url = `${environment.base_url}/login/telefono`;
    return this.http.post<any>(url, data, httpOptions);
  }

  get windowRef(){
    return window;
  }
  

  // Servicio que obtiene los datos de cada cliente, recibe el modelo key(CI/NIT)
  doRegister(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      })
    };
    const url = `${environment.base_url}/usuario`;
    return this.http.post<any>(url, data,httpOptions);
  }

  doLogin(value:any){
    
  }

  doLogout(){
    localStorage.removeItem('userKaizen');
  }
}
