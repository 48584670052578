export const environment = {
  production: false,
  base_url: 'https://qa-ws-kaizen-motors.kaizenmotors.com/api/v1',
  //base_url: 'https://ws-kaizen-motors.kaizenmotors.com/api/v1',
  sentry_env : 'testing',
  //sentry_dsn: "https://86562600644446ae9adb5ac59a8c129c@o268498.ingest.sentry.io/5044160",
  sentry_dsn: "",
  //base_url: 'http://192.168.69.6:8000/api/v1',
  firebase : {
    apiKey: "AIzaSyAdQtwkmnEQb3VsA-WmWmfEFYmmGA9ghYU",
    authDomain: "kaizen-ecommerce.firebaseapp.com",
    databaseURL: "https://kaizen-ecommerce.firebaseio.com",
    projectId: "kaizen-ecommerce",
    storageBucket: "",
    messagingSenderId: "398634671869",
    appId: "1:398634671869:web:e9d565b61104eb3a"
  },
  cyberSourceID : '1snn5n9w'
};
