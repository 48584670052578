import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MenuHeaderRoutingModule } from './menu-header-routing.module';
import { MenuHeaderComponent } from './menu-header.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { MobileComponent } from './mobile/mobile.component';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { RepuestoModule } from '../repuesto/repuesto.module';
import { CatalogoModule } from '../catalogo/catalogo.module';
import { UserModule } from '../user/user.module';

@NgModule({
  exports: [
    MenuHeaderComponent
  ],
  declarations: [
    MenuHeaderComponent,
    MobileComponent
  ],
  imports: [
    CommonModule,
    MenuHeaderRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NzLayoutModule,
    NzFormModule,
    NzInputModule,
    NzMenuModule,
    NzDrawerModule,
    NzDividerModule,
    NzIconModule,
    NzBadgeModule,
    NzCardModule,
    NzButtonModule,
    NzAvatarModule
  ]
})
export class MenuHeaderModule { }
