import { NgModule } from '@angular/core';
import { BrowserModule, Meta, Title } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { es_ES } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MenuHeaderModule } from './menu-header/menu-header.module';
import { UserService } from './services/core/user.service';
import { AgmCoreModule } from '@agm/core';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { GtagModule } from 'angular-gtag';
import { FooterModule } from './footer/footer.module';

registerLocaleData(es);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD8GCvYWVRiv0mRNOmbKFzz7kg8RPFYHag',
      libraries: ['places']
    }),
    GtagModule.forRoot({ trackingId: 'GTM-M6NCNT3', trackPageviews: true }),
    MenuHeaderModule,
    FooterModule,
    NzNotificationModule,
  ],
  providers: [Title, Meta, UserService, { provide: NZ_I18N, useValue: es_ES }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
