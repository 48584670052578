import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  marcas$= new EventEmitter<any>();
  repuestos$= new EventEmitter<any>();
  partnumber$ = new EventEmitter<any>();

  constructor() { }
}
