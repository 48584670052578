import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  token: string | null;

  constructor(private http: HttpClient) { 
    const userLocal = localStorage.getItem('userKaizen');
    if (userLocal) {
      this.token = JSON.parse(userLocal).token;
    } else {
      this.token = "No exite token";
    }
  }

  getCurrentUser(){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': `Bearer ${this.token}`
      })
    };
    const url = `${environment.base_url}/usuario/perfil`;
    return this.http.get<any>(url, httpOptions);

  }

  editUser(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': `Bearer ${this.token}`
      })
    };
    const url = `${environment.base_url}/usuario/perfil`;
    return this.http.post<any>(url, data,httpOptions);
  }

  updateCurrentUser(value:any){
    
  }
  
  postVehiculosUser(data: any){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': `Bearer ${this.token}`
      })
    };
    const url = `${environment.base_url}/teros/cliente/vehiculo`;
    return this.http.post<any>(url, data,httpOptions);

  }

  getVehiculosUser(){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': `Bearer ${this.token}`
      })
    };
    const url = `${environment.base_url}/teros/cliente/vehiculo`;
    return this.http.get<any>(url, httpOptions);

  }

  getCitasUser(){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': `Bearer ${this.token}`
      })
    };
    const url = `${environment.base_url}/teros/cliente/cita`;
    return this.http.get<any>(url, httpOptions);

  }

  getHistorialServiciosUser(placa:any){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': `Bearer ${this.token}`
      })
    };
    const url = `${environment.base_url}/teros/cliente/servicio/historial/placa/${placa}`;
    return this.http.get<any>(url, httpOptions);

  }

  getHistorialCitasUser(){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': `Bearer ${this.token}`
      })
    };
    const url = `${environment.base_url}/teros/cliente/servicio/historial`;
    return this.http.get<any>(url, httpOptions);

  }
}

