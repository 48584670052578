import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: () => import('./inicio/inicio.module').then(m => m.InicioModule)},
  { path: 'inicio', loadChildren: () => import('./inicio/inicio.module').then(m => m.InicioModule) },
  { path: 'usuario', loadChildren: () => import('./user/user.module').then(m => m.UserModule) },
  { path: 'servicio-tecnico', loadChildren: () => import('./agendado-citas/agendado-citas.module').then(m => m.AgendadoCitasModule) }, 
  { path: 'carrito', loadChildren: () => import('./ecommerce/ecommerce.module').then(m => m.EcommerceModule) }, 
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'contacto', loadChildren: () => import('./contacto/contacto.module').then(m => m.ContactoModule) },
  { path: 'encuesta', loadChildren: () => import('./encuesta/encuesta.module').then(m => m.EncuestaModule) },
  { path: 'pagar', loadChildren: () => import('./formas-pago/formas-pago.module').then(m => m.FormasPagoModule) },
  { path: 'liquidacion/repuestos', loadChildren: () => import('./ofertas/ofertas.module').then(m => m.OfertasModule) },
  { path: 'ofertas', loadChildren: () => import('./ofertas/ofertas.module').then(m => m.OfertasModule) },
  { path: 'blackweek/oferta-de-parachoques', loadChildren: () => import('./ofertas/ofertas.module').then(m => m.OfertasModule) },
  { path: 'blackweek', loadChildren: () => import('./ofertas/ofertas.module').then(m => m.OfertasModule) },
  //{ path: 'busqueda/:termino', component: BusquedaComponent},
  { path: 'busqueda/:termino', loadChildren: () => import('./busquedas/busquedas.module').then(m => m.BusquedasModule) },
  { path: 'repuesto/:marca', loadChildren: () => import('./catalogo/catalogo.module').then(m => m.CatalogoModule) },
  { path: 'repuestos/:marca', loadChildren: () => import('./catalogo/catalogo.module').then(m => m.CatalogoModule) },
  { path: 'bolsa-trabajo', loadChildren: () => import('./bolsa-trabajo/bolsa-trabajo.module').then(m => m.BolsaTrabajoModule) },
  //{ path: 'bolsa-trabajo/:id', component: DetalleComponent},
  //{ path: 'postular/:id', component: FormularioPostularComponent},
  { path: ':marca/:codigo', loadChildren: () => import('./repuesto/repuesto.module').then(m => m.RepuestoModule) },
  //{ path: 'chaperio', component: ChaperioComponent },
  { path: 'footer', loadChildren: () => import('./footer/footer.module').then(m => m.FooterModule) },
  //{ path: 'condiciones-de-uso', component: CondicionesUsoComponent },
  //{ path: 'devoluciones', component: DevolucionesComponent },
  //{ path: 'politicas-de-privacidad', component: PoliticasPrivacidadComponent },
  { path: 'error404', loadChildren: () => import('./error404/error404.module').then(m => m.Error404Module) },
  { path: 'loader', loadChildren: () => import('./loader/loader.module').then(m => m.LoaderModule) },
  { path: 'busquedas', loadChildren: () => import('./busquedas/busquedas.module').then(m => m.BusquedasModule) },
  { path: 'politicas-de-privacidad', loadChildren: () => import('./politicas-de-privacidad/politicas-de-privacidad.module').then(m => m.PoliticasDePrivacidadModule) },
  { path: 'condiciones-de-uso', loadChildren: () => import('./condiciones-de-uso/condiciones-de-uso.module').then(m => m.CondicionesDeUsoModule) },
  { path: 'devoluciones', loadChildren: () => import('./devoluciones/devoluciones.module').then(m => m.DevolucionesModule) },
  { path: 'chaperio', loadChildren: () => import('./servicio-chaperio/servicio-chaperio.module').then(m => m.ServicioChaperioModule) },
  { path: 'sucursales', loadChildren: () => import('./sucursales/sucursales.module').then(m => m.SucursalesModule) },
  { path: '**', redirectTo: 'error404' }
]
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
