<app-menu-header></app-menu-header>
<router-outlet></router-outlet>
<app-footer></app-footer>
<!--
<div class="rrss">
    <div class="rrss-widgets contenedores" *ngIf="visible">
        <div [@flyInOut] class="container-toyowidget">
            <div class="capsule">
                <div class="rrss-nm">WhatsApp</div>
                <a class="toyowidget wp" href="https://api.whatsapp.com/send?phone=+59169831219&text=Hola,%20estaba%20en%20la%20página%20web%20y%20tengo%20una%20consulta" target="_blank">
                    <img src="../assets/icons/wapp.svg" width="50" height="50" alt="Icono de whatsapp" class="widget-wp"/>
                </a>
            </div>
        </div>
        <div [@fadeInOut] class="container-toyowidget">
            <div class="capsule">
                <div class="rrss-nm">Facebook Messenger</div>
                <a class="toyowidget ms" href="https://m.me/116526075075064" target="_blank">
                    <img src="../assets/icons/logo_msn.svg" width="50" height="50" alt="Icono de Messenger" class="widget-wp"/>
                </a>
            </div>
        </div>
        <div [@fadeInOut] class="container-toyowidget">
            <div class="capsule">
                <div class="rrss-nm">Llamada</div>
                <a class="toyowidget call"  href="tel:800175200" target="_blank">
                    <img [src]="imagenchat" width="50" height="50" alt="Icono de llamada" class="widget-wp"/>
                </a>
            </div>
        </div>
    </div>
    <div class="container-toyowidget contenedores" id="toyoclose" *ngIf="visible"  (click)="close()">
        <div class="capsule">
            <a class="toyowidget ty">
                <img src="../assets/icons/btn_cerrar.svg" width="50" height="50" alt="Icono de cerrar" class="widget-wp"/>
            </a>
        </div>
    </div>

    <div class="container-toyowidget contenedores" id="toyoopen" *ngIf="!visible" >
        <div class="capsule">
            <a class="toyowidget_toyosa ty" href="https://api.whatsapp.com/send?phone=+59175008888&text=Hola,%20estaba%20en%20la%20página%20web%20y%20tengo%20una%20consulta" target="_blank">
                <img [src]="imagenchat" width="30" height="30" alt="Icono de live chat" class="widget-wp"/>
                <span>Chat</span>
            </a>
        </div>
    </div>
</div>-->