import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageloadService {
  constructor(private http: HttpClient) { }

  loadImage(ruta:string): Observable<Blob> {
    return this.http.get(ruta, { responseType: 'blob'});
  }

  loadImageCDN(rutaimagen:any) {
    this.loadImage(rutaimagen)
    .subscribe((response: Blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(response);
      reader.onloadend = () => {
        let datofin = reader.result as string;
        return datofin;
      };
    },
    (error) => {
      console.error('Error al cargar la imagen:', error);
    });
  }
}