import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {
  token: string | null;

  constructor(private http: HttpClient) { 
    const userLocal = localStorage.getItem('userKaizen');
    if (userLocal) {
      this.token = JSON.parse(userLocal).token;
    } else {
      this.token = "No exite token";
    }
  }

  // Servicio que obtiene las marcas de los repuestos
  agregarProductoCarro(data:any) {
    //console.log(this.token);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      })
    };
    const url = `${environment.base_url}/compra/producto`;
    return this.http.post<any>(url, data, httpOptions);
  }

  // Servicio que obtiene el pago red
  pagoTarjetaBancaria() {
    console.log(this.token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      })
            
    };
    const url = `${environment.base_url}/pago`;
    return this.http.post<any>(url,'', httpOptions);
  }

  // Servicio que obtiene las marcas de los repuestos
  productosCarro() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      })
    };
    //console.log(this.token);
    const url = `${environment.base_url}/compra/producto`;
    return this.http.get<any>(url, httpOptions);
  }

  deleteItemCart(id: string){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      })
    };
    const url = `${environment.base_url}/compra/producto/${id}`;
    return this.http.delete(url, httpOptions);
  }

  actualicarCantidadProducto(data:any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      })
    };
    const url = `${environment.base_url}/compra/producto`;
    return this.http.post<any>(url, data, httpOptions);
  }

  agregarDireccion(data:any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      })
    };
    const url = `${environment.base_url}/pago/direccion`;
    return this.http.post<any>(url, data, httpOptions);
  }

  editarDireccion( id: any, data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      })
    };
    const url = `${environment.base_url}/pago/direccion/${id}`;
    return this.http.put<any>(url, data, httpOptions);
  }

  eliminarDireccion(id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      })
    };
    const url = `${environment.base_url}/pago/direccion/${id}`;
    return this.http.delete<any>(url, httpOptions);
  }

  listarDireccion() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      })
    };
    const url = `${environment.base_url}/pago/direccion`;
    return this.http.get<any>(url, httpOptions);
  }

  historyShoping(){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      })
    };
    const url = `${environment.base_url}/compra/pedido`;
    return this.http.get<any>(url, httpOptions);
  }

   // Servicio que obtiene las marcas de los repuestos
  datosFacturacion(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      })
    };
    const url = `${environment.base_url}/usuario/facturacion`;
    return this.http.post<any>(url, data, httpOptions);
  }
}
