import { DOCUMENT } from '@angular/common';
import { Component, Inject, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { first } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { DataService } from './services/data.service';
import { ProductosfiltrosService } from './services/ecommerce/productosfiltros.service';
import { ImageloadService } from './services/imageload.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'website-kaizen-motors';

  disabled = false;
  codeRedEnlaceInput:any;
  popupImage:string = '17-04.webp';
  visible=false;
  imagenchat:any;

  constructor(private cargarimagen: ImageloadService, private _renderer2: Renderer2, @Inject(DOCUMENT) private _document: Document,  private titleService: Title, private meta: Meta, private filtrosProductosService: ProductosfiltrosService, private dataService: DataService) {
    this.obtenerMarcas()

    

    /*let elemento:any = this._document.getElementById('kaizenhead');
    let script:any = this._renderer2.createElement('script');
    script.type = 'application/javascript';
    script.src = 'https://h.online-metrix.net/fp/tags.js?org_id='+environment.cyberSourceID+'&session_id=redenlace_400709'+codeData;
    this._renderer2.appendChild(elemento, script);*/

    let titulo = "Kaizen Motors: Repuestos | Servicio Técnico | Chaperio";

    this.titleService.setTitle(titulo);
    this.meta.addTags([
      { property: 'og:title', content: titulo },
      { property: 'og:description', content: 'Empresa automotriz lider en tecnologias digitales.En nuestra página web o app puedes comprar todos los repuestos de las marcas #Toyota #Lexus y #Daihatsu que necesites. También puedes hacer citas para nuestro taller. Sitio web' } ,
      { property: 'og:image', content: 'https://www.kaizenmotors.com/assets/images/popups/'+ this.popupImage },
      { name: 'keywords', content: 'Kaizen Motors, Repuestos, eCommerce, eCommerce de repuestos, Servicio Técnico, Agendado de citas, Chaperio, Taller Tecnológico, Bolivia, Toyota, Daihatsu, Asakashi, Denzo, Lexus, Automóviles' }
    ]);

    this.addSchema();
    this.addAsyncAttributeToLinks();
    this.loadImage();
  }

  loadImage() {
    this.cargarimagen.loadImage('https://res.cloudinary.com/cdn-kaizen-motors/image/upload/v1720116421/production/plataforma-kaizen/web/assets/iconos/icono_whatsapp.png')
    .subscribe((response: Blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(response);
      reader.onloadend = () => {
        this.imagenchat = reader.result as string;
      };
    },
    (error) => {
      console.error('Error al cargar la imagen:', error);
    });
  }

  obtenerMarcas() {
    this.filtrosProductosService.getMarcas().pipe(first()).subscribe( data => {
      //console.log(data);
      this.dataService.marcas$.emit(data);
    }, error => {
        console.log(error); // Imprimimos en consola el error
    });
  }

  generarSerieRedEnlace(longitud: number) {
    let a = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'.split('')
    let b = []
    for (let i = 0; i < longitud; i++) {
      let j :any = (Math.random() * (a.length - 1)).toFixed(0)
      b[i] = a[j]
    }
    return b.join('');
  }

  addAsyncAttributeToLinks() {
    const linkElements = document.querySelectorAll('link[rel="stylesheet"]');
    linkElements.forEach((link: any) => {
      link.setAttribute('async', '');
    });
  }

  insertarSerieRedEnlaceDocumento(code: string){
    document.head.innerHTML += '<script type="text/javascript" src="https://h.online-metrix.net/fp/tags.js?org_id='+environment.cyberSourceID+'&session_id=redenlace_400709'+code+'"></script>';
    //console.log('<script src="https://h.online-metrix.net/fp/tags.js?org_id='+environment.cyberSourceID+'&session_id=redenlace_400709'+code+'" type="text/javascript"></script>');
  }
  onmousemove() {
    this.visible = true;
    setTimeout(() => {
      this.visible = false;
    }, 5000);
    
  }
  open() {
    this.visible = true;
  }

  close() {
      this.visible = false;
  }
  addSchema(){
    let elemento = this._document.getElementById('kaizenhead');
    let script = this._renderer2.createElement('script');
    script.type = `application/ld+json`;
    script.text = `
    {
      "@context": "https://schema.org",
      "@type": "AutomotiveBusiness",
      "image": [
        "https://res.cloudinary.com/cdn-kaizen-motors/image/upload/v1688732886/production/plataforma-kaizen/web/assets/app/app-v2.webp"
       ],
      "name": "Kaizen Motors: Repuestos | Servicio Técnico | Chaperio",
      "geo": [
        {
        "@type": "GeoCoordinates",
        "latitude": -17.8552489,
        "longitude": -63.2955824
      	},
        {
        "@type": "GeoCoordinates",
        "latitude": -17.8078964,
        "longitude": -63.2422861
      	}
      ],
      "url": "https://www.kaizenmotors.com/",
      "telephone": "+59133225555"
    }
    `;
    this._renderer2.appendChild(elemento, script);
  }

}
