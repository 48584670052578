import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { ImageloadService } from '../services/imageload.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  noMostrar=false;
  imagefacebook:any;
  imageyoutube:any;
  imagelinkedin:any;

  constructor(private cargarimagen: ImageloadService,private router: Router) { 
    this.loadImage();
  }

  ngOnInit(): void {
    this.router.events.subscribe( (event: Event) => {
      if (event instanceof NavigationStart) {
        scrollTo(0, 0);
        //this.viewModelsBlock = false;
      }

      if (event instanceof NavigationEnd) {
        const pathname = window.location.pathname;
        
        switch (pathname) {
              
              case '/pagar/pago-tarjeta':
                const valores = window.location.search;
                console.log(valores);
                if(valores) {
                  this.noMostrar = true;
                  
                } else {
                  this.noMostrar = false;
                }
                break;
              
              default:
                this.noMostrar = false;
                break;
            }
      }

      if (event instanceof NavigationError) {
        scrollTo(0, 0);
        //this.viewModelsBlock = false;
      }
    });
    
  }

  loadImage() {
    this.cargarimagen.loadImage('https://res.cloudinary.com/cdn-kaizen-motors/image/upload/v1715208418/production/plataforma-kaizen/web/assets/iconos/facebook.png')
    .subscribe((response: Blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(response);
      reader.onloadend = () => {
        this.imagefacebook = reader.result as string;
      };
    },
    (error) => {
      console.error('Error al cargar la imagen:', error);
    });

    this.cargarimagen.loadImage('https://res.cloudinary.com/cdn-kaizen-motors/image/upload/v1715208417/production/plataforma-kaizen/web/assets/iconos/icono_youtube_blanco.png')
    .subscribe((response: Blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(response);
      reader.onloadend = () => {
        this.imageyoutube = reader.result as string;
      };
    },
    (error) => {
      console.error('Error al cargar la imagen:', error);
    });

    this.cargarimagen.loadImage('https://res.cloudinary.com/cdn-kaizen-motors/image/upload/v1715208418/production/plataforma-kaizen/web/assets/iconos/icono_linkedin_blanco.png')
    .subscribe((response: Blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(response);
      reader.onloadend = () => {
        this.imagelinkedin = reader.result as string;
      };
    },
    (error) => {
      console.error('Error al cargar la imagen:', error);
    });
  }

}
