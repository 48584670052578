import { Component, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { AuthService } from 'src/app/services/core/auth.service';
import { UserService } from 'src/app/services/core/user.service';
import { DataService } from 'src/app/services/data.service';
import { ProductosfiltrosService } from 'src/app/services/ecommerce/productosfiltros.service';
import { ShoppingCartService } from 'src/app/services/ecommerce/shopping-cart.service';
import { ImageloadService } from 'src/app/services/imageload.service';


@Component({
  selector: 'app-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.css']
})
export class MobileComponent implements OnInit, OnDestroy {
  logo = 'https://res.cloudinary.com/cdn-kaizen-motors/image/upload/v1715265313/production/plataforma-kaizen/web/assets/logo-kaizen-motors-blanco.svg';
  marcas = [];
  nombreUsuario = "KAIZEN MOTORS"; 
  userData: any;
  userJson:any;
  searchDesktop: any;
  cantidadCart = 0;
  logueado: boolean;
  visibleMenuMobile = false;
  verbuscador;

  noMostrar=false;

  /* web view */
  web=true;
  estadoSuscription: Subscription | undefined;
  menumobile:any;

  constructor(private cargarimagen: ImageloadService, public userService: UserService, public authService: AuthService, private filtrosProductosService: ProductosfiltrosService,
      private carro: ShoppingCartService, private router: Router, private dataService: DataService) {
          this.logueado = false;
          this.visibleMenuMobile = false;
          /*this.filtrosProductosService.getMarcas().pipe(first()).subscribe( data => {
              this.marcas = Object.values(data);
          }, error => {
              console.log(error);
          });*/

      this.verbuscador = false;
      this.loadImage();
  }
  
  loadImage() {
    this.cargarimagen.loadImage('https://res.cloudinary.com/cdn-kaizen-motors/image/upload/v1715265041/production/plataforma-kaizen/web/assets/iconos/menu-mobile.png')
    .subscribe((response: Blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(response);
      reader.onloadend = () => {
        this.menumobile = reader.result as string;
      };
    },
    (error) => {
      console.error('Error al cargar la imagen:', error);
    });
    
  }

  ngOnInit() {
    this.estadoSuscription = this.dataService.marcas$.subscribe(estado => {
      this.marcas = Object.values(estado);
    });
    
    this.router.events.subscribe( (event: Event) => {
      if (event instanceof NavigationStart) {
        scrollTo(0, 0);
        //this.viewModelsBlock = false;
      }

      if (event instanceof NavigationEnd) {
        scrollTo(0, 0);
        this.inicializandoEsquema();
        const pathname = window.location.pathname;
        
        switch (pathname) {
              case '/login':
                this.noMostrar = true;
                break;
    
              case '/servicio-tecnico':
                this.noMostrar = false;
                this.logo = 'https://res.cloudinary.com/cdn-kaizen-motors/image/upload/v1715265313/production/plataforma-kaizen/web/assets/logo-kaizen-motors-blanco.svg';
                
                break
              case '/tienda-virtual':
                this.noMostrar = false;
                break;
              
              case '/pagar/pago-tarjeta':
                const valores = window.location.search;
                console.log(valores);
                if(valores) {
                  this.web=false;
                  this.noMostrar = true;
                  
                } else {
                  this.web=true;
                  this.noMostrar = false;
                }
                break;
              
              default:
                this.noMostrar = false;
                this.logo = 'https://res.cloudinary.com/cdn-kaizen-motors/image/upload/v1715265313/production/plataforma-kaizen/web/assets/logo-kaizen-motors-blanco.svg';
                break;
            }
      }

      if (event instanceof NavigationError) {
        scrollTo(0, 0);
        //this.viewModelsBlock = false;
      }
    });
    
  }

  ngOnDestroy(): void {
    this.estadoSuscription?.unsubscribe();
  }

  inicializandoEsquema() {
    this.userData = localStorage.getItem('userKaizen');
    this.userJson = JSON.parse(this.userData);

    if(this.userJson != null){
        this.nombreUsuario = "Hola, "+(this.userJson['nombre'].split(' ')[0]);
        this.logueado = true;
        this.obtenerItemsCart();
    } else {
      this.nombreUsuario = "KAIZEN MOTORS";
      this.logueado = false;
    }

  }

  onChange(value: any): void {
    console.log(value);
  }

  onKey(e:any) {
    let searchKaizen = {};
    if(this.searchDesktop.length > 2) {
      if(e.key == "Enter"){
          console.log(this.searchDesktop);
          this.router.navigate(['/busqueda/'+this.searchDesktop]);
      }
    }
  }

  linkSearch() {
    let searchKaizen = {};
      this.router.navigate(['/busqueda/'+this.searchDesktop]);
      //console.log(this.searchDesktop);
  }

  viewSearch() {
    this.verbuscador = true;

    setTimeout(() => {
      this.verbuscador = false;
    }, 5000);
  }

  logout(){
    this.userData = localStorage.getItem('userKaizen');
    this.userJson = JSON.parse(this.userData);
    console.log("User: " + this.userJson);

    if(this.userJson != null){
      localStorage.removeItem('userKaizen');
      localStorage.removeItem('kaizenSocial');
      localStorage.removeItem('pago');
      localStorage.removeItem('efectivoKaizen');
      localStorage.removeItem('tarjeta');
      localStorage.removeItem('deliveryKaizen');
      localStorage.removeItem('codeRE');
      localStorage.clear();
      this.nombreUsuario = "KAIZEN MOTORS";
      this.logueado = false;
      this.router.navigate(['/']);
      //this.location.back();
    } else {
      this.logueado = true;
    }
  }

  openMobile(): void {
    console.log("ingresa");
    this.visibleMenuMobile = true;
  }

  closeMobile(): void {
    this.visibleMenuMobile = false;
  }

  obtenerItemsCart() {
      this.carro.productosCarro().pipe(first()).subscribe(
        data => {
          if(data){
            this.cantidadCart = data.detalle_productos.length;
          }
          
         // console.log(data.detalle_productos.length);
        }, error => {
          this.cantidadCart = 0;
          //console.log(error); // Imprimimos en consola el error
          console.log(error.status); // Imprimimos en consola el error
          //Gestion de errores
          setTimeout(() => {
            //this.errorCode.getErrorCode(error.status);
          }, 2000);
      });
  }
  getUrl(nombre: string) {
    return "../repuesto/"+nombre.toLowerCase();
  }
}
